.topbar {
	background: $lightgray;
	text-align: center;
	a {
		color: $text;
		margin: 0 1rem;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 0.7rem;
	}
}

nav {
	background: #fff;
	border-bottom: 1px solid $lightgray;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	position: relative;
	z-index: 99;
	&.navbar {
		padding: 0.25rem 0.75rem;
	}
	.container {
		width: 100%;
	}
	.navbar-toggler {
		border: 0;
		padding: 0;
		&.navbar-toggler-right {
			top: 13px;
		}
	}
}

.navbar-nav {
	border-top: 1px solid $lightgray;
	padding-top: 0.25rem;
	text-align: center;	
	margin-top: 15px;
	text-transform: uppercase;
	font-size: 0.8rem;
	.nav-item {
		&:last-of-type {
			margin-right: 0;
		}

		.nav-link {
			display: inline-block;
			padding: 0.25rem 0.75rem;
			font-weight: 700;
			&:hover {
				color: $red;
			}
			&.active {
				background: $red;
				color: #fff;
				position: relative;
				&:after {
					position: absolute;
			    content: '';
			    width: 15px;
			    height: 100%;
			    right: -15px;
			    top: 0px;
			    background: linear-gradient(to bottom right, $red 50%, transparent 50%);
				}
				&:before {
					content: '';
					position: absolute;
					left: -15px;
					top: 0;
					width: 15px;
					height: 100%;
					background: linear-gradient(to top left, $red 50%, transparent 50%);
				}
			}
		}
	}
}