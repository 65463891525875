.is--flex {
	display: flex;
}

.is--sticky {
	position: fixed;
	top: 0;
	left: 0; 
	width: 100%;
	z-index: 999;
}