// -----------------------------
// Input Material
// -----------------------------

input {
  color: #000 !important;
}

.alert-success {
  margin: 2rem 0;
  border: 0;
  padding: 1rem;
  border-radius: 0;
}

.alert-danger {
  margin: 2rem 0;
  border: 0;
  padding: 1rem;
  border-radius: 0;
}

#path_cv-error {
  display: none !important;
}

.error {
  background: rgba(red, 0.8);
  display: inline-block;
  padding: 0.25rem;
  color: #fff;
  font-size: 0.7rem;
}

.input__material-wrapper {
	position: relative;
	margin: 1.2rem 0;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #000;
    transition: 0.25s ease-out;
  }
  &.active:after {
    width: 100%;
    transition: 0.25s ease-out;
  }
	input {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $text;
    border-radius: 0;
    outline: none;
    height: 2.5rem;
    width: 100%;
    font-size: .8rem;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
    font-family: 'Lato', sans-serif;
 }

	label {
    font-family: 'Lato', sans-serif;
		color: #000;
    position: absolute;
    top: 0.4rem;
    left: 0;
    font-size: 0.8rem;
    cursor: text;
    transition: .2s ease-out;
    text-align: initial;
    z-index: -1;
    &.active {
    	transform: translateY(-120%);
    	font-size: .8rem;
    	transition: all 0.2s;
    }
  }
}


// -----------------------------
// Select Material
// -----------------------------
.select__material-wrapper {
  margin: 1.2rem 0;
  label {
    font-size: 0.8rem;
    font-family: 'Lato', sans-serif;
    color: #000;
  }
  select {
    width: 100%;
  }
  .select2-container--default .select2-selection--single {
    border: 0;
    border-bottom: 1px solid $text;
    border-radius: 0;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-family: 'Lato', sans-serif !important;
    font-size: .9rem !important;
    padding-left: 0;
  }

}

// -----------------------------
// Radio Material
// -----------------------------
.radio__material-wrapper {
  margin-top: 1.2em;
  input[type="radio"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    &+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 4px;
      width: 20px;
      height: 20px;
      z-index: 0;
      border: 1px solid $text;
      border-radius: 50%;
      transition: .28s ease;
    }
    &+label:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      background: #000;
      border-radius: 50%;
      border: 0;
      width: 10px;
      height: 10px;
      margin: 9px;
      transform: scale(0);
      transition: .28s ease;
    }
    &:checked+label:after {
      border: 0;
      transform: scale(1);
      transition: .28s ease;
    }
  }
  span {
    font-size: 0.8em;
    color: #000;
  }
  label {
    font-size: 0.8em;
    color: $text;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    transition: .28s ease;
  }
  ul {
    padding: 0;
    list-style: none;
  }
}


// -----------------------------
// Checkbox Material
// -----------------------------
.checkbox__material-wrapper {
  margin-top: 1.2rem;
  span {
    font-size: .8rem;
    color: #000;
  }

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    &+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 4px;
      width: 20px;
      height: 20px;
      z-index: 0;
      border: 1px solid $text;
      transition: .28s ease;
    }
    &+label:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      background: #000;
      border: 0;
      width: 10px;
      height: 10px;
      margin: 9px;
      transform: scale(0);
      transition: .28s ease;
    }
    &:checked+label:after {
      border: 0;
      transform: scale(1);
      transition: .28s ease;
    }
  }

  label {
    font-size: 0.8em;
    color: $text;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
    }
  }
}