// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
	
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

	section.about {
		img.freccia-rossa {
			bottom: -35px;
		}
	}

		.main__news .social ul {
			margin: 0 0 1rem 0;
		}

		.row__studi {
			border: 0;
			padding: 0
		}
		
		footer .footer__box {
			margin: 2rem 0;
		}

		section.about {
			padding: 5rem 0;
		} 
		.navbar .logo {

		}

		#mappa {
			height: 330px;
			margin: 0;
		}

		.nav-tabs .nav-link.active:after {
			height: 20px;
			bottom: -20px;
			background-size: contain;
		}

		section.gallery {
			padding: 4rem 0 2rem 0;
			.col-1-5 {
				padding: 0 1rem;
				margin: 1rem 0;
				width: 33%;
				float: left;
			}
		}

		footer .footer__box h5 {
			font-size: 1rem;
			display: flex;
			align-self: flex-end;
			width: 100%;
		}
		footer .footer__box-title {
			height: 34px;
			display: flex;
		}

		footer .footer__contacts {
			margin-top: 1.5rem;
		}

		section.where-we-operate {
			padding-top: 4rem;
		}
				
		.table-responsive {
			display: table;
		}

		.row__liste-speciali {
			margin-bottom: 2rem;
		}

		.select__material-wrapper {
			margin-top: 0.1rem;
		}

		.navbar {
			// background: url(/images/background-logo.png) no-repeat left center;
			.logo {
				width: 300px;
				margin-left: -35px;
			}
		}

		nav .navbar-toggler.navbar-toggler-right {
			top: 20px;
		}

		.topbar .justify-content-center {
			justify-content: none !important;
		}
		.topbar {
			text-align: right;
		}
		.topbar a:last-of-type {
			padding-right: 0;
		}


		// -------------------------
		// Home Carousel with news
		// -------------------------

		.hero__carousel-wrapper {
			// overflow-y: visible;
			// overflow-x: hidden !important;
		}


		.hero__carousel {
			// overflow-y: visible;
			// overflow-x: hidden !important;
			.slide {
				height: 60vh;
			}
		}

		.news__wrapper {
			width: 100%;
			position: absolute;
			bottom: 10px;
			right: -40%;
			background: rgba(0,0,0,0.5);
			margin: 0;
			padding-top: 1rem;

			&:before {
				position: absolute;
				content: '';
				width: 27.5%;
    		height: 100%;
    		left: -27.5%;
    		top: 0px;
				background: linear-gradient(to top left, rgba(0,0,0,0.5) 50%, transparent 50%);
			}
	
			h3 {
				position: absolute;
				bottom: 0.5rem;
				left: -12%;
				text-transform: uppercase;
				color: #fff;
				font-size: 1.5rem;
			}

			.arrow-news {
				position: absolute;
				bottom: 1rem;
				left: -20%;
				cursor: pointer;
				width: 30px;
				transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
				&.rotate {
					transform: rotate(-180deg);
					transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
				}
			}
		}

		.news__box .news__description {
			color: #fff;
		}

		.news__box {
			max-width: 200px;
			.news__description {
				font-size: 0.7rem;
			}
		}

}	

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	.bg {
		background-color: #fff;
		background: url(/images/logo-background.svg) no-repeat;
		background-position: -375px center;
		width: 100%;
		height: 100px;
	}
	.navbar {
		padding:  0 !important;
	}

	.navbar-collapse {
		margin-top: -1.5rem;
	}

	.main__news {
		padding: 4rem 0;
	}

	.single-news__description {
		margin: 1.5rem 0;
	}

	.navbar .logo {
		opacity: 0;
	}
	.navbar-brand {
		margin-right: 0;
	}
	.navbar-nav {
		padding-top: 0;
	}
	// .navbar-collapse {
	// 	position: relative;
	// 	&:before {
	// 		position: absolute;
	// 		content: '';
	// 		width: 250%;
	// 		height: 1px;
	// 		background: $red;
	// 		left: 0;
	// 		top: 4px;
	// 	}
	// }

	.mappa__wrapper {
		position: relative;
		width: 100%;
		#linea-left {
			position: absolute;
			left: -25px;
			top: 50%;
			transform: translate(0,-50%);
		}

		#linea-right {
			position: absolute;
			right: -25px;
			top: -60px;
			z-index: 1;
		}


		// .dashed{
		//   stroke-dasharray: 10;
		// }
		.dashed {
		  stroke-dasharray: 1000;
		  stroke-dashoffset: 1000;
		  animation: dash 5s linear alternate infinite;
		}

		@keyframes dash {
		  from {
		    stroke-dashoffset: 1000;
		  }
		  to {
		    stroke-dashoffset: 0;
		  }
		}

	}
	
	.white-popup {
		width: 80%;
		max-width: 1200px;
	}

	#lost-and-found-modal {
		padding: 3rem;
		h3 {
			margin-bottom: 4rem;
		}
	}

	#mappa {
		height: 350px;
	}

	footer .footer__box h5:after {
		height: 18px;
	}

	.news__wrapper {
		right: -53%;
	}

	.hero__carousel .slide {
		height: 70vh;
	}
	
	section.gallery {
		.col-1-5 {
			width: 25%;
		}
	}


	.row__language {
		ul li {
			display: inline-block;
			margin-right: 2rem;
		}
	}

	nav .container {
		width: 960px;
	}
	
	nav.navbar {
		padding: 1rem;
	}

	.navbar {
		.logo {
			width: 268px;
		}
	}

	.navbar-nav {
		border: 0;
		margin-top: 0;
		.nav-item {
			margin: 0 0.25rem;
			.nav-link {
				font-size: 0.6rem;
				padding: 0.25rem;
				&:hover:not('.active') {
					color: $red;
				}
			}
		}
	}


	.navbar-nav .nav-item .nav-link.active:before {
    border-width: 0 0 24px 17px;
	}

	.navbar-nav .nav-item .nav-link.active:after {
    border-width: 24px 17px 0 0;
	}

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

	section.job-applications {
		padding: 6rem 0 4rem 0;
	}

	section.about {
		img.freccia-rossa {
			bottom: -35px;
		}
	}

	.bg {
		background-color: #fff;
		background: url(/images/logo-background.svg) no-repeat;
		background-position: -300px center;
		width: 100%;
		height: 100px;
	}
	
	.navbar-collapse {
		margin-top: -3.3rem;
	}

	.hero__carousel .slide .slide__caption {
		padding: 2rem;
	}

	.hero__carousel .slide .slide__caption h2 {
		font-size: 2.5rem;
	}

	.navbar-nav .nav-item .nav-link.active:before {
    border-width: 0 0 27px 17px;
	}

	.navbar-nav .nav-item .nav-link.active:after {
    border-width: 27px 17px 0 0;
	}

	section.about {
		// margin: 2rem 0;
	}

	.hero__carousel {
			.slide {
				height: 70vh;
				min-height: 500px;
			}
		}

	.news__wrapper {
		right: -64%;
	}
	.news__wrapper:before {
		width: 20%;
		left: -20%;
	}

	.news__wrapper h3 {
		left: -8%;
	}

	.news__wrapper .arrow-news {
		left: -12%;
	}
	
	nav .container {
		width: 1140px;
	}

	nav.navbar {
		padding: 0.7rem 0;
	}

	.navbar {
		.logo {
			width: 403px;
		}
	}

	// .left-texture {
	// 	position: absolute;
	// 	left: 0;
	// 	top: 51%;
	// 	width: 25vw;
	// 	height: 8px;
	// 	transform: scale(1.19);
	// 	background: url(/images/logo-texture-lines.svg);
	// }

	.navbar-nav {
		border: 0;
		margin-top: 0;
		.nav-item {
			margin: 0 0.5rem;
			.nav-link {
				font-size: 0.8rem;
				padding: 0.25rem;
				&:hover:not('.active') {
					color: $red;
				}
			}
		}
	}

	section.gallery {
		.col-1-5 {
			width: 20%;
		}
	}

}

@media (min-width: 1400px) {
	.bg {
		background-color: #fff;
		background: url(/images/logo-background.svg) no-repeat;
		background-position: -230px center;
		width: 100%;
		height: 100px;
	}

}

@media (min-width: 1600px) {

	.nav__wrapper .container {
		width: 1400px;
	}

	.bg {
		background-color: #fff;
		background: url(/images/logo-background.svg) no-repeat;
		background-position: center center;
		width: 100%;
		height: 100px;
	}

}

@media (min-width: 2000px) {
	html {
		font-size: 112%;
	}

	.bg {
		background-color: #fff;
		background: url(/images/logo-background.svg) no-repeat;
		background-position: center center;
		width: 100%;
		background-size: cover;
		height: 130px;
	}

	.navbar-collapse {
		margin-top: -2.5rem;
	}

}
