section.gallery {
	background: $lightergray;
	text-align: center;
	padding: 3rem 0 2rem 0;
	position: relative;
	&:before {
		position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 0px;
    left: 50%;
    margin-left: -75px;
    border-style: solid;
    border-width: 30px 75px 0 75px;
    border-color: #ffffff transparent transparent transparent;
    z-index: 9;
	}
	&:after {
		position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: 50%;
    margin-left: -75px;
    border-style: solid;
    border-width: 30px 75px 0 75px;
    border-color: $lightergray transparent transparent transparent;
    z-index: 9;
	}
	h3 {
		color: $red;
		text-transform: uppercase;
		margin-bottom: 2rem;
	}

	.slick-slide {
		img {
			margin: auto;
		}
	}

	.slick-dots {
		position: static;
	}
}