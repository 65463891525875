.clients__carousel {
	.clients__item {
		margin: 1rem auto;
		img {
			margin: auto;
		}
	}
	&.offset-carousel {
		margin-left: -20%;
	}
}