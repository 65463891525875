// Import here all the scss partials.
@import 'base/colors';
@import 'base/base';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/main';
@import 'layout/headers';
@import 'layout/hero-carousel';
@import 'layout/clients-carousel';
@import 'layout/gallery-carousel';
@import 'layout/cookiebar';
@import 'module/buttons';
@import 'module/form';
@import 'module/news-box';
@import 'module/nav-tabs';
@import 'module/modal';
@import 'state/state';
@import 'responsive/responsive';