section.where-we-operate.main__jobs {
	margin: 3rem auto;
}


section.about, section.services, section.corporate {
	h3 {
		text-align: center;
		color: $red;
		text-transform: uppercase;
	}
}
section.about {
	.about--wrapper {
		margin: 3rem 0;
	}
}

section.services {
	background: #f2f2f2;
	h3 {
		margin-bottom: 3rem;
	}
	padding: 6rem 0 3rem 0;
}

section.corporate {
	text-align: center;
	position: relative;
	padding: 4rem 0;
	&:after {
		position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -29px;
    left: 50%;
    margin-left: -75px;
    border-style: solid;
    border-width: 30px 75px 0 75px;
    border-color: #fff transparent transparent transparent;
    z-index: 9;
	}
}

.news__container {
	position: relative;
	.close-btn {
		position: absolute;
		top: 0;
		right: 2rem;
		z-index: 10;
		i {
			font-size: 2.5rem;
			color: $red;
		}
	}
}

.main__news {
	padding: 2rem 0;
	.social {
		ul {
			padding: 0;
			list-style: none;
			margin: 1rem 0;
			li {
				display: inline-block;
				margin-right: 0.5rem;
			}
			.social__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				background:#83847B;
				border-radius: 50%;
				width: 40px;
				height: 40px;
				text-align: center;
				padding: 0.5rem;
				transition: 0.25s;
				&:hover {
					opacity: 0.8;
					transition: 0.25s;
				}
				i {
					color: #fff;
					font-size: 1rem;
				}
			}
		}
	}

	.date {
		font-weight: 700;
	}

	h1 {
		color: $red;
		font-size: 1.8rem;
		margin: 1rem 0;
	}
}


.main__jobs {
	margin-bottom: 2rem;
}

.job__section {
	margin-top: 2rem;
	h3 {
		text-transform: uppercase;
		font-size: 1.4rem;
		color: $red;
		font-weight: 700;
	}
	h4 {
		text-transform: uppercase;
		font-size: 1rem;
		color: #000;
		font-weight: 700;
	}
}

.row__patente {
	label {
		margin-right: 1rem;
	}
}

.row__studi {
	border-bottom: 1px solid #bebebe;
	padding-bottom: 1rem;
}

.row__language {
	ul li {
		display: inline-block;
		margin-right: 1rem;
	}
	span {
		color: #000;
		font-size: .8rem;
		display: inline-block;
		margin-top: 1.2rem;
	}
	.input__material-wrapper {
		margin-top: 0.5rem;
	}
}

.table__esperienze-lavorative {
	margin-top: 3rem;
	thead th {
		border-color: $text;
		border-top: 0;
		border-bottom: 1px solid $text;
		text-align: center;
		color: #000;
		font-size: .8rem;
		&:first-of-type {
			border-left: 0;
		}
		&:last-of-type {
			border-right: 0;
		}
	}
	td {
		text-align: center;
		border-color: $text;
		&:first-of-type {
			border-left: 0;
		}
		&:last-of-type {
			border-right: 0;
		}
	}

	i {
		color: #000;
		font-size: 1.2rem;
		cursor: pointer;
	}

	span.date {
		display: block;
		font-size: .8rem;
	}

	input {
		border: 0;
		color: #000;
		font-size: .8rem;
		width: 100%;
		font-family: 'Lato', sans-serif;
	}
	textarea {
		border: 0;
		color: #000;
		font-size: .8rem;
		width: 100%;
		font-family: 'Lato', sans-serif;
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $text;
	  font-size: .8rem;
	  font-family: 'Lato', sans-serif;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: $text;
	  font-size: .8rem;
	  font-family: 'Lato', sans-serif;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: $text;
	  font-size: .8rem;
	  font-family: 'Lato', sans-serif;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: $text;
	  font-size: .8rem;
	  font-family: 'Lato', sans-serif;
	}

	.date {
		text-align: center;
	}

}

.add__experience-wrapper {
	margin: 1rem auto;
	text-align: center;
	.add__experience-icon {
		border-radius: 50%;
		width: 45px;
		height: 45px;
		border: 1px solid $text;
		margin: auto;
		text-align: center;
		padding: 0.6rem 0;
	}
	i {
		color: #000;
		font-size: 1.5rem;
	}
	span {
		font-size: .8rem;
		color: #000;
	}
}

.allega__documenti {
	.file__upload {
		margin: 1rem 0;
	}
	i {
		font-size: 2rem;
		color: #000;
		margin-right: 1rem;
	}
	span {
		margin-right: 1rem;
	}
}

.trattamento-dati {
	padding: 2rem;
	border-top: 1px solid $text;
	border-bottom: 1px solid $text;
	p {
		font-size: .7rem;
		padding-left: 2rem;
	}
}


// ----------------------------
// Home page
// ----------------------------

.intro {
	text-align: center;
	margin-bottom: 1rem;
	h3 {
		font-size: 1.5rem;
	}
}

section.about {
	padding: 2rem 0;
	border-bottom: 1px solid $red;
	position: relative;
	img.freccia-rossa {
		position: absolute;
		bottom: -34px;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

section.lost-and-found {
	background: url(/images/lost-and-found-bg.jpg) no-repeat center center;
	width: 100%;
	padding: 4rem 0;
	background-size: cover;
	text-align: center;
	position: relative;
	&:before {
		position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 0px;
    left: 50%;
    margin-left: -75px;
    border-style: solid;
    border-width: 30px 75px 0 75px;
    border-color: #f2f2f2 transparent transparent transparent;
    z-index: 9;
	}
	&:after {
		position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: 50%;
    margin-left: -75px;
    border-style: solid;
    border-width: 30px 75px 0 75px;
    border-color: #371609 transparent transparent transparent;
    z-index: 9;
	}
	.lost-and-found__wrapper {
		h3 {
			color: #fff;
			text-transform: uppercase;
		}
		p {
			color: #fff;
		}
	}
}

section.clients {
	text-align: center;
	padding: 4rem 0 2rem 0;
	h3 {
		color: $red;
		text-transform: uppercase;
	}
}



section.where-we-operate {
	text-align: center;
	padding: 3rem 0 0 0;
	h3 {
		color: $red;
		text-transform: uppercase;
	}
	strong {
		font-weight: 500;
	}
	ul {
		padding: 0;
		margin: 2rem 0;
		list-style: none;
		span {
			color: $red;
		}
	}
}

#mappa {
	width: 100%;
	height: 250px;
	margin: 2rem 0 0 0;
}
section.job-applications {
	width: 100%;
	background: url(/images/job-application-bg.jpg) no-repeat center center;
	background-size: cover;
	text-align: center;
	padding: 4rem 0 2rem 0;
	position: relative;
	// &:before {
	// 	position: absolute;
 //    content: '';
 //    width: 0;
 //    height: 0;
 //    top: 0px;
 //    left: 50%;
 //    margin-left: -75px;
 //    border-style: solid;
 //    border-width: 30px 75px 0 75px;
 //    border-color: #ffffff transparent transparent transparent;
 //    z-index: 9;
	// }
	&:after {
		position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: 50%;
    margin-left: -75px;
    border-style: solid;
    border-width: 30px 75px 0 75px;
    border-color: #912216 transparent transparent transparent;
    z-index: 9;
	}
	h3 {
		color: #fff;
		text-transform: uppercase;
	}
	p {
		color: #fff;
	}
}

section.privacy-policy {
	padding: 2rem 0;
	h1 {
		text-transform: uppercase;
		color: $red;
		font-size: 1.8rem;
	}
	h3 {
		color: $red;
		text-transform: uppercase;
		font-size: 1.2rem;
	}
	p {
		text-align: justify;
	}
	a {
		color: $red;
	}
	ul {
		li a {
			color: $red;
		}
	}
}