.news__box {
	max-width: 280px;
	margin: 0 0.5rem;
	.news__image {
		margin-bottom: 1rem;
	}
	.news__description {
		font-size: 0.8rem;
		text-align: left;
	}
}
