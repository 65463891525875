.hero__carousel-wrapper {
	position: relative;
}

.hero__carousel {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		bottom: 0px;
		width: 100%;
		height: 10px;
		z-index: 99;
		background: $red;
	}
	&:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		bottom: -30px;
		left: 50%;
		margin-left: -75px;
		border-style: solid;
		border-width: 30px 75px 0 75px;
		border-color: $red transparent transparent transparent;
		z-index: 9;
	}

	.slide {
		position: relative;
		.slide__caption {
			position: absolute;
			top: 0;
			left: 0;
			padding: 1rem;
			background: rgba(0,0,0,0.5);
			z-index: 9;
			&:after {
				position: absolute;
		    content: '';
		    width: 23%;
		    height: 100%;
		    right: -23%;
		    top: 0px;
		    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.5) 50%, transparent 50%);
			}
			h2 {
				font-size: 1.5rem;
				color: #fff;
				font-weight: 300;
				margin: 0;
			}
		}
	}
	.slide01 {
		background: url(/images/slide01.jpg) no-repeat center center;
		width: 100%;
		height: 300px;
		background-size: cover;
	}
}

.news__wrapper {
	margin: 3rem 0 1rem 0;
	text-align: center;
	 transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
	&.open {
		right: -30%;
		 transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
	}
}
