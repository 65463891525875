#cookie-bar {box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.49); background: #000; height:auto; color:#fff; text-align:center; padding: 20px; font-size: 0.8em; z-index: 10000; position: absolute; top: 0; left: 0; width: 90%;}
#cookie-bar.fixed {position:fixed; bottom: 40px; left: 50%; width: 90%; transform: translate(-50%, 0); -webkit-transform: translate(-50%, 0); -moz-transform: translate(-50%, 0); -ms-transform: translate(-50%, 0); -o-transform: translate(-50%, 0);}
#cookie-bar.fixed.bottom {bottom: 40px; top:auto;}
#cookie-bar p {margin:0; padding:0; height: auto;}
#cookie-bar a {color:#fff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:transparent; border: 1px solid $red; padding: 0.3em 1.2em; margin-left: 15px; color: $red; cursor: pointer;}
#cookie-bar .cb-enable:hover {background:transparent;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}
#cookie-bar .cookie-link-2 {
	margin: 0;
	padding: 0;
	color: $red;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

@media screen and (max-width: 480px) {
	#cookie-bar .cb-enable {margin-top: 10px;}
}