footer {
	background: $lightergray;
	padding: 4rem 0 2rem 0;
	text-align: center;
	h3 {
		color: $red;
		text-transform: uppercase;
	}
	.contacts-row {
		margin: 3rem 0;
	}

	.footer__box {
		text-align: left;
		margin: 0 0 2rem 0;
		h5 {
			color: $red;
			font-weight: 700;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				left: 0;
				background: url(/images/border-image.svg) no-repeat center center;
				background-size: cover;
				bottom: -25px;
				height: 15px;
			}
		}
		span {
			display: block;
		}
	}

	.footer__contacts {
		margin-top: 2.5rem;
	}
}

.series-of-hr {
	hr {
		width: 100%;
		background: #fff;
		border: 0;
		margin: 0.55rem 0;
	}
	@for $i from 1 through 4 {
	  hr:nth-child(#{$i}) { height: 16px - 3px * $i; }
	}
}

.copyright {
	font-size: 0.6rem;
	margin-top: 2rem;
	a {
		font-weight: 700;
		color: $text;
		font-size: 0.75rem;
	}
}

