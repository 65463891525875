.btn {
	&.btn-primary {
		border: 0;
		border-radius: 0;
		background: #fff;
		color: $red;
		text-transform: uppercase;
		padding: 1rem 2rem;
		margin-top: 1rem;
	}
	&.btn__job-applications {
		width: 100%;
		display: block;
		margin: 3rem 0 1rem 0;
		border: 0;
		color: #fff;
		text-transform: uppercase;
		border-radius: 0;
		background: $red;
		cursor: pointer;
		font-size: 1.4rem;
	}
	&.btn--red {
		background: $red;
		color: #fff;
	}
}