.header {
	width: 100%;
	height: 250px;
	border-bottom: 10px solid $red;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		bottom: -30px;
		left: 50%;
		margin-left: -75px;
		border-style: solid;
		border-width: 30px 75px 0 75px;
		border-color: $red transparent transparent transparent;

	}

	&.header__job {
		background: url(/images/header-job.jpg) no-repeat center center;
		background-size: cover;
	}

	&.header__news {
		background: url(/images/header-news.jpg) no-repeat center center;
		background-size: cover;
	}
}