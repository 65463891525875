body {
	color: $text;
	font-family: 'Lato', sans-serif;
	position: relative;
}

.main__wrapper {
	overflow: hidden;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
}

