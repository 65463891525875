.nav-tabs {
	border: 0;
	margin-bottom: 2rem;
	.nav-item {
		font-family: 'Raleway', sans-serif;
		text-transform: uppercase;
		font-weight: 700;
		width: 50%;
		text-align: center;
		position: relative;
	}
	.nav-link {
		color: $text;
		border: 0;
		padding: 1rem 0.5rem;
		&.active {
			color: $red;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				left: 0;
				background: url(/images/border-image.svg) no-repeat center center;
				background-size: cover;
				bottom: -5px;
				height: 10px;
			}
		}
	}
}

.tab-content p, .tab-content ul  {
	max-width: 485px;
	margin: 1rem auto;
	text-align: justify;
}