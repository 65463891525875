.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: 100%;
  margin: 20px auto;
}

#lost-and-found-modal {
	border: 8px solid $red;
	padding: 1rem;
	h3 {
		color: $red;
		text-transform: uppercase;
		text-align: center;
	}

	.lost-and-found__box {
		margin: 2rem 0;
		border-bottom: 1px solid $text;
			&:last-of-type {
				border: 0;
			}
		h5 {
			color: $red;
			text-transform: uppercase;
			font-weight: 700;
		}
	}
}